import React from "react";
import { Outlet } from "react-router-dom";

import { Header } from "../../../bundles/Distributors/components/Header";
import { Sidebar } from "../../../bundles/Distributors/components/Sidebar";

function Layout({ app_contexts }) {
  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
}

export default Layout;
