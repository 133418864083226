import React from "react";
import { cn } from "../utils/classNames";

const Button = ({ children, onClick, disabled, active, className }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "relative inline-flex items-center rounded-md bg-slate-50 p-2 text-xs font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 focus:z-10 enabled:hover:z-20 enabled:hover:bg-indigo-50 enabled:hover:text-indigo-900 enabled:hover:ring-indigo-300 disabled:cursor-not-allowed disabled:bg-slate-100 disabled:text-slate-400",
        active && "z-20 bg-indigo-50 text-indigo-900 ring-indigo-300",
        className
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
