import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faExclamationCircle,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import Button from "./Button";

const MutationButton = ({
  mutation,
  variables,
  onSuccess,
  children,
  className,
  loadingMessage = "Loading...",
  successMessage = "Success!",
  errorMessage = "Error",
  ...buttonProps
}) => {
  const { mutate, isLoading, isSuccess, isError, error } = mutation;

  const handleClick = () => {
    mutate(variables, {
      onSuccess: onSuccess || (() => {}),
    });
  };

  return (
    <Button onClick={handleClick} disabled={isLoading} className={className} {...buttonProps}>
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
      ) : isSuccess ? (
        <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-green-500" />
      ) : isError ? (
        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2 text-red-500" />
      ) : null}
      {isLoading ? loadingMessage : isSuccess ? successMessage : isError ? errorMessage : children}
    </Button>
  );
};

export default MutationButton;
