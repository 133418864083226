import React from "react";

const ActionBar = ({ children }) => (
  <div
    className={`-mx-4 flex items-center justify-between space-x-2 border-b border-gray-200 bg-white px-4 py-2 `}
  >
    {children}
  </div>
);

export default ActionBar;
