import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

import Button from "./Button";

const Pagination = ({ pagination }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const currentPageSize = parseInt(searchParams.get("page_size") || "10", 10);

  const pageSizes = [1, 2, 5, 10, 25, 50, 100];

  const getPageUrl = (page, pageSize = currentPageSize) => {
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    params.set("page_size", pageSize);
    return `${location.pathname}?${params.toString()}`;
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value, 10);
    navigate(getPageUrl(1, newPageSize));
  };

  const renderPageLinks = () => {
    const pages = [];
    const totalPages = pagination.total_pages;
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, currentPage + 1);

    if (start > 1) {
      pages.push(
        <Link key="first" to={getPageUrl(1)}>
          <Button className="rounded-none">
            <span className="px-1 text-xs">1</span>
          </Button>
        </Link>
      );
      if (start > 2) {
        pages.push(
          <span
            key="ellipsis1"
            className="relative inline-flex items-center rounded-none bg-slate-50 px-3 py-2 text-xs font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300"
          >
            ...
          </span>
        );
      }
    }

    for (let i = start; i <= end; i++) {
      pages.push(
        <Link key={i} to={getPageUrl(i)}>
          <Button className="rounded-none" active={i === currentPage}>
            <span className="px-1 text-xs">{i}</span>
          </Button>
        </Link>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pages.push(
          <span
            key="ellipsis2"
            className="relative inline-flex items-center rounded-none bg-slate-50 px-3 py-2 text-xs font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300"
          >
            ...
          </span>
        );
      }
      pages.push(
        <Link key="last" to={getPageUrl(totalPages)}>
          <Button className="rounded-none">
            <span className="px-1 text-xs">{totalPages}</span>
          </Button>
        </Link>
      );
    }

    return pages;
  };

  return (
    <div className="-m-4 flex items-center justify-between border-t border-gray-200 bg-white px-4 pt-2">
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          to={getPageUrl(currentPage - 1)}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            pagination.previous_page_allowed ? "" : "cursor-not-allowed bg-gray-200 text-gray-400"
          }`}
          onClick={(e) => !pagination.previous_page_allowed && e.preventDefault()}
        >
          Previous
        </Link>
        <Link
          to={getPageUrl(currentPage + 1)}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            pagination.next_page_allowed ? "" : "cursor-not-allowed bg-gray-200 text-gray-400"
          }`}
          onClick={(e) => !pagination.next_page_allowed && e.preventDefault()}
        >
          Next
        </Link>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Page <span className="font-medium">{currentPage}</span> of{" "}
            <span className="font-medium">{pagination.total_pages}</span>
          </p>
        </div>
        <div>
          <nav
            aria-label="Pagination"
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          >
            <Link to={getPageUrl(currentPage - 1)}>
              <Button
                disabled={!pagination.previous_page_allowed}
                className="rounded-none rounded-l-md text-xs"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon aria-hidden="true" className="h-4 w-4" />
              </Button>
            </Link>
            {renderPageLinks()}
            <Link to={getPageUrl(currentPage + 1)}>
              <Button
                disabled={!pagination.next_page_allowed}
                className="rounded-none rounded-r-md"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon aria-hidden="true" className="h-4 w-4" />
              </Button>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
