import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import React, { Fragment } from "react";
import {
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  deliveryDocument,
  getDeliveriesListQueryOptions,
  useDeliveriesCancel,
  useDeliveriesList,
} from "@/apis/distributor";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import MutationButton from "@/common/components/MutationButton";
import NoResults from "@/common/components/NoResults";
import Pagination from "@/common/components/Pagination";
import StatusBadge from "@/common/components/StatusBadge";
import ActionBar from "@/common/components/ActionBar";
import InputField from "@/common/components/InputField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { far } from "@awesome.me/kit-989a8e6dbe/icons";

import useDocumentDownload from "../../hooks/useDocumentDownload";

import PartbotIcon from "@/common/components/PartbotIcon";

export const loader =
  (queryClient) =>
  async ({ params, request }) => {
    const url = new URL(request.url);
    const page = url.searchParams.get("page") || "1";
    const page_size = url.searchParams.get("page_size") || "1";
    const query = url.searchParams.get("query") || "";
    return await queryClient.ensureQueryData(
      getDeliveriesListQueryOptions({
        location_ids: [params.locationId],
        page: page,
        page_size: page_size,
        query: query,
        type: "shipped",
      })
    );
  };

export default function Shipped() {
  const { locationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: { results: bookings, pagination },
    queryKey: deliveriesQueryKey,
  } = useDeliveriesList({
    location_ids: [locationId],
    page: searchParams.get("page"),
    page_size: searchParams.get("page_size"),
    query: searchParams.get("query") || "",
    type: "shipped",
  });

  const handleSearch = (searchTerm) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("query", searchTerm);
      newParams.set("page", "1"); // Reset to first page on new search
      return newParams;
    });
  };

  return (
    <div className="space-y-5">
      <BookingCards 
        bookings={bookings} 
        locationId={locationId} 
        queryKey={deliveriesQueryKey} 
        onSearch={handleSearch}
        initialSearchTerm={searchParams.get("query") || ""}
      />
      {bookings?.length >= 1 && <Pagination pagination={pagination} />}
      {bookings?.length === 0 && <NoResults message="No shipped deliveries found" />}
    </div>
  );
}

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const options = { month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const documents = [
  { type: "SHIPPING_MANIFEST", label: "Manifest" },
  { type: "SHIPPING_COMMERCIAL_INVOICE", label: "Commercial Invoice" },
  { type: "SHIPPING_LABEL", label: "Shipping Label" },
  { type: "SHIPPING_CONNOTE", label: "Connote" },
  { type: "SHIPPING_DG_DOCUMENT", label: "Dangerous Goods Declaration" },
  { type: "POD", label: "Proof of Delivery" },
];

const BookingCard = ({ booking, locationId, queryKey }) => {
  const { handleDownload, downloadStatus } = useDocumentDownload(deliveryDocument);

  const queryClient = useQueryClient();
  const cancelDeliveryMutation = useDeliveriesCancel({
    mutation: {
      onSuccess: (data) => {
        console.log("Booking cancelled", data);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        console.error("Error cancelling booking", error);
      },
    },
  });

  const totalWeight = booking.items.reduce(
    (acc, item) => acc + item.unit_weight * item.quantity,
    0
  );
  const dangerousGoods = booking.items.some((item) => item.dangerous_goods); // Assuming there's a dangerous_goods field

  return (
    <div className="relative my-4 grid h-36 grid-cols-12 gap-2 rounded-lg border border-gray-200 text-sm shadow-sm">
      <div className="col-span-2 h-full space-y-2 rounded-s-lg border-e border-gray-200 bg-gray-50 p-2">
        <div className="flex items-center">
          <span className="text-lg font-semibold leading-none tracking-wide">
            {booking.references.reference1}
          </span>
        </div>
        <div className="flex-shrink-0 flex-grow">
          <div className="flex items-center" title="Partbot Reference">
            <PartbotIcon className="me-1.5 h-3.5 w-4 fill-current text-slate-400" />
            <a
              href={`${process.env.PARTBOT_TRACKING_URL}/${booking.references.reference2}`}
              target="_blank"
              rel="noreferrer"
            >
              {booking.references.reference2}
            </a>
          </div>
          <div className="flex items-center" title="Store Reference">
            <FontAwesomeIcon icon={far.faStore} className="me-1.5 h-4 w-4 text-slate-400" />
            {booking.references.reference3}
          </div>
          <div className="flex items-center" title="Store Reference">
            <FontAwesomeIcon icon={far.faTruckPlane} className="me-1.5 h-4 w-4 text-slate-400" />
            {booking.fe_tracking_number}
          </div>
        </div>
      </div>
      <div className="col-span-2 py-2">
        <div className="mb-2 font-semibold text-gray-800">Origin</div>
        <div className="mb-2 text-gray-600">
          {booking.origin.city} {booking.origin.state} {booking.origin.post_code}
          <br />
          {booking.origin.country_code}
        </div>
        <div className="text-gray-500">{booking.origin.contact_name}</div>
      </div>
      <div className="col-span-2 py-2">
        <div className="mb-2 font-semibold text-gray-800">Destination</div>
        <div className="mb-2 text-gray-600">
          {booking.destination.city} {booking.destination.state} {booking.destination.post_code}
          <br />
          {booking.destination.country_code}
        </div>
        <div className="text-gray-500">{booking.destination.contact_name}</div>
      </div>
      <div className="col-span-2 py-2">
        <div className="mb-2 font-semibold text-gray-800">Details</div>

        <div className="text-gray-600">{booking.prices[0].carrier_name}</div>
        <div className="mb-2 text-gray-500">{booking.prices[0].service_name}</div>

        <div className="text-gray-600">
          {booking.items.length} Items {totalWeight} kg
        </div>
        {dangerousGoods && <div className="font-semibold text-red-500">Dangerous Goods</div>}
      </div>
      <div className="col-span-2 py-2">
        <div className="mb-2 font-semibold text-gray-800">Dates</div>
        <dl className="grid grid-cols-2 text-xs text-gray-600">
          <dd>Created</dd>
          <dt>{formatDate(booking.create_date)}</dt>
          <dd>Earliest Pickup</dd>
          <dt>
            {formatDate(booking.expected_pickup_dates?.earliest)} -{" "}
            {formatDate(booking.expected_pickup_dates?.latest)}
          </dt>
          <dd>Earliest Delivery</dd>
          <dt>
            {formatDate(booking.expected_delivery_dates?.earliest)} -{" "}
            {formatDate(booking.expected_delivery_dates?.latest)}
          </dt>
        </dl>
      </div>
      <div className="col-span-2 h-full p-2 text-right">
        <div className="flex h-full flex-col items-end justify-between">
          <Menu as="div" className="relative inline-block text-left text-xs">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-3 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                Documents
                <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="z-20 py-1">
                  {documents.map((doc, i) => (
                    <Menu.Item key={doc.type}>
                      {({ active }) => (
                        <button
                          tabIndex={i === 0 ? 0 : -1}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDownload(
                              doc.type,
                              booking.fe_tracking_number,
                              doc.label,
                              booking.id
                            );
                          }}
                          className={`${
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } z-10 flex w-full items-center justify-between px-4 py-2 text-left text-xs`}
                        >
                          {doc.label}
                          {downloadStatus[doc.label]?.loading ? (
                            <Spinner className="mr-8 h-3 w-3 animate-spin" />
                          ) : null}
                          {downloadStatus[doc.label]?.error && (
                            <span className="text-red-500">
                              <FontAwesomeIcon icon={far.faExclamationTriangle} />
                            </span>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <MutationButton
            mutation={cancelDeliveryMutation}
            variables={{ id: booking.id }}
            className="ml-2"
            successMessage="Cancelled"
            errorMessage="Error Cancelling"
            loadingMessage="Cancelling..."
          >
            Cancel
          </MutationButton>
        </div>
      </div>
      <div className="absolute bottom-2 left-2">
        <StatusBadge status={booking.latest_event?.status} />
      </div>
    </div>
  );
};


const BookingCards = ({ bookings, locationId, queryKey, onSearch, initialSearchTerm }) => {

  const { handleSubmit, control } = useForm(
    {
      defaultValues: {
        search: initialSearchTerm,
      },
    },
    { mode: "onBlur" }
  );

  const handleSearchSubmit = (formData) => {
    const searchTerm = formData.search;
    onSearch(searchTerm);
  };

  return (
    <div className="space-y-4">
      <div className="relative w-full">
        <ActionBar className="flex items-center justify-between">
          <div className="flex items-center">
            {/* Bulk Actions */}
          </div>
          <form onSubmit={handleSubmit(handleSearchSubmit)}>
            <InputField
              name="search"
              label="Search"
              placeholder="Search by order or reference number ..."
              control={control}
              className="!w-96 !py-2"
              icon={<FontAwesomeIcon icon={far.faSearch} className="text-gray-500" />}
            />
          </form>
        </ActionBar>
      {bookings?.map((booking) => (
        <BookingCard
          key={booking.id}
          booking={booking}
          locationId={locationId}
          queryKey={queryKey}
        />
      ))}
      </div>
    </div>
  );
};

const Spinner = () => (
  <svg
    className="ml-4 h-3 w-3 animate-spin text-indigo-700"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 2.686 8.165 6.553 9.747l1.447-2.456z"
    ></path>
  </svg>
);
