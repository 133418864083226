import React from "react";

const NoResults = ({ message }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      <p className="text-lg text-gray-500">{message}</p>
    </div>
  );
};

export default NoResults;
