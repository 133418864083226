import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

export const DimensionsBadge = ({ dimensions }) => {
  return (
    <span className="rounded bg-slate-100 p-1 text-xs font-medium text-slate-700/80">
      <span className="font-semibold">{dimensions.length}</span>
      <span className="mx-0.5 text-slate-700/50">
        <FontAwesomeIcon icon={faXmark} className="h-2.5 w-2.5" />
      </span>
      <span className="font-semibold">{dimensions.width}</span>
      <span className="mx-0.5 text-slate-700/50">
        <FontAwesomeIcon icon={faXmark} className="h-2.5 w-2.5" />
      </span>
      <span className="font-semibold">{dimensions.height}</span>
      <span className="ml-0.5 text-slate-700/50">cm</span>
    </span>
  );
};
