import React from "react";

import { Checkbox, Input, TextArea } from "../../UI/components/Controls";

export default function ProductSettings({ user }) {
  const isAdmin = user.role.title === "Admin";
  return (
    <div className="space-y-5 divide-y divide-gray-200">
      <div className="space-y-5 divide-y divide-gray-200">
        <div className="pt-5">
          <div role="group" aria-labelledby="label-email">
            <div className="grid grid-cols-3 items-baseline gap-4">
              <div>
                <div className="text-sm font-medium text-gray-700" id="label-email">
                  Availability
                </div>
              </div>
              <div className="col-span-2 mt-4 md:mt-0">
                <div className="space-y-4">
                  <div className="relative flex items-start">
                    <Checkbox name="published" label="Published" />
                  </div>

                  {isAdmin && (
                    <div className="relative flex items-start">
                      <Checkbox name="preorder" label="Preorder" note="" />
                    </div>
                  )}

                  {isAdmin && (
                    <div className="relative flex items-start">
                      <Checkbox name="allow_dropship" label="Dropshippable" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div role="group" aria-labelledby="label-email">
            <div className="grid grid-cols-3 items-baseline gap-4">
              <div>
                <div className="text-sm font-medium text-gray-700" id="label-email">
                  OEM Part
                </div>
              </div>
              <div className="col-span-2 mt-4 md:mt-0">
                <div className="space-y-4">
                  <div className="relative flex items-start">
                    <Checkbox
                      name="is_oem"
                      label="Is this an Original Equipment Manufacturer (OEM) Part?"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isAdmin && (
          <div className="pt-5">
            <div role="group" aria-labelledby="label-email">
              <div className="grid w-full grid-cols-3 items-baseline gap-4">
                <div>
                  <div className="text-sm font-medium text-gray-700" id="label-email">
                    Cataloguing
                  </div>
                </div>
                <div className="col-span-2 mt-4 md:mt-0">
                  <div className="space-y-4">
                    <div className="relative flex items-start">
                      <Checkbox
                        name="lock_details"
                        label="Lock Details"
                        note=" Lock title, and description from being updated by external
                      syncs"
                      />
                    </div>
                    <div className="relative flex items-start">
                      <Checkbox
                        name="generic_image"
                        label="Generic Image"
                        note="Partbot will add an Example Image watermark to the primary image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-3 items-start gap-4 border-t border-gray-200 pt-5">
          <label className="mt-px block pt-2 text-sm font-medium text-gray-700">Notes</label>
          <div className="col-span-2 mt-1 md:mt-0">
            <TextArea name="catalogue_notes" label="Notes" rows={5} defaultValue="" />
          </div>
        </div>

        {isAdmin && (
          <div className="grid grid-cols-3 items-start gap-4 border-t border-gray-200 pt-5">
            <label className="mt-px block pt-2 text-sm font-medium text-gray-700">
              Search Priority Score
            </label>
            <div className="col-span-1 mt-1 md:mt-0">
              <Input
                name="search_priority"
                label="Search Priority"
                type="number"
                min={0}
                max={100}
                step={1}
              />
              <div className="mt-2 text-xs text-gray-500">
                Value between 0-100. Higher values will appear first in search results.
              </div>
            </div>
          </div>
        )}

        {isAdmin && (
          <div className="grid grid-cols-3 items-start gap-4 border-t border-gray-200 pt-5">
            <label className="mt-px block pt-2 text-sm font-medium text-gray-700">
              TecAlliance (TecDoc)
            </label>
            <div className="col-span-1 mt-1 md:mt-0">
              <Input name="ta_article_id" label="Article ID" type="text" />
            </div>
          </div>
        )}

        {isAdmin && (
          <div className="grid grid-cols-3 items-start gap-4 border-t border-gray-200 pt-5">
            <label className="mt-px block pt-2 text-sm font-medium text-gray-700">
              AutoInfo (Oscar)
            </label>
            <div className="col-span-1 mt-1 md:mt-0">
              <Input name="product_code" label="AutoInfo APIES ID" type="text" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
