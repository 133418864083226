import React, { useState } from "react";
import Select from "react-select";

export const MaropostImageAllocationInput = ({ value = "Main", onChange = () => {} }) => {
  const [selectedValues, setSelectedValues] = useState(value?.split(",") || ["Main"]);

  const options = [
    { value: "Main", label: "Main" },
    ...Array.from({ length: 10 }, (_, i) => ({
      value: `Alt ${i + 1}`,
      label: `Alt ${i + 1}`,
    })),
  ];

  return (
    <Select
      isMulti
      value={options.filter((option) => selectedValues.includes(option.value))}
      onChange={(selectedOptions) => {
        console.log("selectedOptions", selectedOptions);
        const newValues = selectedOptions.map((option) => option.value);
        setSelectedValues(newValues);
        const hiddenInput = document.getElementById(
          "maropost_integration_primary_image_allocation",
        );
        hiddenInput.value = newValues;
        onChange(newValues.join(","));
      }}
      options={options}
      placeholder="Select image allocation"
    />
  );
};
