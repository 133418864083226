import React, { useEffect, useRef, useState } from "react";

import axios from "axios";

import { classNames } from "../../../utils";
import { Button } from "../../UI/components";

export const MaropostWarehouseCombobox = ({ integration_id, default_option, default_label }) => {
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [quantity] = useState(25);
  const [inputValue, setInputValue] = useState(default_label);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    // Fetch options from remote server
    setIsLoading(true);

    axios
      .get(`/stores/${integration_id}/maropost/fetch-warehouses`, { params: { page, quantity } })
      .then((response) => {
        // combine the new options with the previous options, removing any duplicates of the same id
        const newOptions = response.data.filter((option) => {
          return !options.some((existingOption) => existingOption.id === option.id);
        });

        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [page, quantity, integration_id, options]);

  const handleInputChange = (event) => {
    // Update the input value
    setInputValue(event.target.value);
  };

  const handleLoadMore = () => {
    // Increment the index and fetch the next batch of options
    setPage(page + 1);
  };

  const handleToggle = () => {
    // Open or close the options list
    setIsOpen(!isOpen);
  };

  //   close the options list when the user clicks outside of the combobox
  const comboboxRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [comboboxRef]);

  const handleOptionSelect = (option) => {
    // Update the input value and close the options list
    setInputValue(`${option.id}. ${option.name} (${option.ref})`);
    setSelectedOption(option);
    setIsOpen(false);

    // find the hidden input with id maropost_integration_warehouse_id and set its value to the selected option's id
    const hiddenInput = document.getElementById("maropost_integration_warehouse_id");
    hiddenInput.value = option.id;

    // find the hidden input with id maropost_integration_warehouse_label and set its value to the selected option's label
    const hiddenLabel = document.getElementById("maropost_integration_warehouse_label");
    hiddenLabel.value = `${option.id}. ${option.name} (${option.ref})`;
  };

  return (
    <div className="relative rounded-md shadow-sm" ref={comboboxRef}>
      <input
        type="text"
        value={inputValue}
        // defaultValue={default_label}
        onChange={handleInputChange}
        className="form-input block w-full rounded-md px-3 py-2 leading-5 transition duration-150 ease-in-out"
        onClick={handleToggle}
      />
      {isOpen && (
        <div className="absolute left-0 mt-2 rounded-md bg-white shadow-lg">
          <ul className="shadow-xs max-h-60 divide-y divide-gray-200 overflow-auto rounded-md">
            {options.map((option) => (
              <li
                key={option.id}
                className={classNames(
                  "cursor-default px-3 py-2 text-xs leading-5",
                  option.id === selectedOption?.id || option.id === default_option
                    ? "bg-indigo-500 text-white"
                    : "text-gray-500 hover:bg-gray-50",
                )}
                onClick={() => handleOptionSelect(option)}
              >
                <span className="font-bold">
                  {option.id}. {option.name}
                </span>{" "}
                ({option.ref})
              </li>
            ))}
          </ul>
          {options.length > 0 && (
            <div className="w-full p-2">
              <Button
                showLoading={isLoading}
                type="button"
                onClick={handleLoadMore}
                className="btn btn-sm w-full"
                label="Load more..."
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
