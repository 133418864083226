// validationSchemas.js
import { z } from "zod";

const addressSchema = z.object({
  company: z.string().nullish(),
  name: z
    .string({
      required_error: "A contact name is required",
    })
    .min(1, "A contact name is required")
    .trim()
    .transform((x) => x ?? undefined),
  email: z
    .string({
      required_error: "A contact email is required",
    })
    .min(1, "A contact email is required")
    .email("Invalid contact email")
    .trim()
    .transform((x) => x ?? undefined),
  phone: z
    .string({
      required_error: "A contact number is required",
    })
    .min(1, "A contact number is required")
    .trim()
    .transform((x) => x ?? undefined),
  address1: z
    .string()
    .min(1, "Address Line 1 is required")
    .trim()
    .transform((x) => x ?? undefined),
  address2: z.string().nullish(),
  city: z
    .string()
    .min(1, "City is required")
    .trim()
    .transform((x) => x ?? undefined),
  subdivision_code: z
    .string()
    .min(1, "State/Province is required")
    .trim()
    .transform((x) => x ?? undefined),
  postal_code: z
    .string()
    .min(1, "Postal Code is required")
    .trim()
    .transform((x) => x ?? undefined),
  country: z
    .string()
    .min(1, "Country is required")
    .trim()
    .transform((x) => x ?? undefined),
});

const cartonSchema = z.object({
  id: z.coerce.string(),
  weight: z.coerce.number().positive("Weight must be greater than 0 kg"),
  length: z.coerce.number().positive("Length must be greater than 0 cm"),
  width: z.coerce.number().positive("Width must be greater than 0 cm"),
  height: z.coerce.number().positive("Height must be greater than 0 cm"),
  items: z
    .array(
      z.object({
        quantity: z.number().positive("Quantity must be greater than 0"),
        id: z.number().positive("A unit ID is required"),
      })
    )
    .nonempty("At least one item is required"),
});

export const shipmentSchema = z.object({
  sender: addressSchema,
  recipient: addressSchema,
  cartons: z.array(cartonSchema).nonempty("At least one carton is required"),
});
