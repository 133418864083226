import React, { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink, useParams, useSearchParams } from "react-router-dom";

import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/solid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@awesome.me/kit-989a8e6dbe/icons";

import {
  getFulfillmentsListQueryOptions,
  useFulfillmentById,
  useFulfillmentRequestUpdate,
  useFulfillmentsList,
} from "@/apis/distributor";
import ActionBar from "@/common/components/ActionBar";
import Button from "@/common/components/Button";
import InputField from "@/common/components/InputField";
import NoResults from "@/common/components/NoResults";
import Pagination from "@/common/components/Pagination";
import StatusBadge from "@/common/components/StatusBadge";

export const loader =
  (queryClient) =>
  async ({ params, request }) => {
    const url = new URL(request.url);
    const page = url.searchParams.get("page") || "1";
    const page_size = url.searchParams.get("page_size") || "1";
    const query = url.searchParams.get("query") || "";
    return await queryClient.ensureQueryData(
      getFulfillmentsListQueryOptions({
        location_ids: [params.locationId],
        page: page,
        page_size: page_size,
        query: query,
      }),
    );
  };

export default function Fulfillments() {
  const { locationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: { results: shipments, pagination },
  } = useFulfillmentsList({
    location_ids: [locationId],
    page: searchParams.get("page"),
    page_size: searchParams.get("page_size"),
    query: searchParams.get("query") || "",
  });

  const handleSearch = (searchTerm) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("query", searchTerm);
      newParams.set("page", "1"); // Reset to first page on new search
      return newParams;
    });
  };

  return (
    <div className="space-y-5">
      <FulfillmentCards
        bookings={shipments}
        locationId={locationId}
        onSearch={handleSearch}
        initialSearchTerm={searchParams.get("query") || ""}
      />
      {shipments?.length >= 1 && <Pagination pagination={pagination} />}
      {shipments?.length === 0 && <NoResults message="No fulfillment requests found" />}
    </div>
  );
}

const FulfillmentCards = ({ bookings, locationId, onSearch, initialSearchTerm }) => {
  const { handleSubmit, control } = useForm(
    {
      defaultValues: {
        search: initialSearchTerm,
      },
    },
    { mode: "onBlur" },
  );

  const handleSearchSubmit = (formData) => {
    const searchTerm = formData.search;
    onSearch(searchTerm);
  };

  return (
    <div className="space-y-4">
      <div className="relative w-full">
        <ActionBar className="flex items-center justify-between">
          <div>
            {/* Bulk Actions */}
          </div>
          <form onSubmit={handleSubmit(handleSearchSubmit)}>
            <InputField
              name="search"
              label="Search"
              placeholder="Search by order number or tracking number"
              control={control}
              className="!w-96 !py-2"
              icon={<FontAwesomeIcon icon={far.faSearch} className="text-gray-500" />}
            />
            <button type="submit" className="sr-only">
              Search
            </button>
          </form>
        </ActionBar>
      </div>
      {bookings?.map((booking) => (
        <FulfillmentCard key={booking.id} booking={booking} locationId={locationId} />
      ))}
    </div>
  );
};

const FulfillmentCard = ({ booking, locationId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { control, handleSubmit, watch, setValue } = useForm();
  const [searchParams] = useSearchParams();
  const { refetch: refetchFulfillmentsList } = useFulfillmentsList({
    location_ids: [locationId],
    page: searchParams.get("page"),
    page_size: searchParams.get("page_size"),
    query: searchParams.get("query") || "",
  });

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const {
    data: fullBookingData,
    isLoading: isLoadingBooking,
    refetch: refetchBooking,
  } = useFulfillmentById(booking.id, {
    query: { enabled: false }, // This prevents the query from running automatically
  });

  const handleOpenModal = () => {
    openModal();
    refetchBooking(); // This will trigger the query when the modal is opened
  };

  const { mutate: updateFulfillmentRequest, isLoading } = useFulfillmentRequestUpdate();

  const onSubmit = (data) => {
    const payload = {
      id: booking.id,
      data: {
        status: data.action,
        ...(data.action === "accept" && {
          reference_number: data.referenceNumber,
          merchant_message: data.merchantMessage,
        }),
        ...(data.action === "reject" && {
          rejection_reason: data.rejectionReason,
          merchant_message: data.merchantMessage,
        }),
      },
    };

    console.log("Submitting payload:", payload);

    updateFulfillmentRequest(payload, {
      onSuccess: () => {
        console.log("Fulfillment request updated successfully");
        closeModal();
        // You might want to refetch the fulfillments list or update the local state here
      },
      onError: (error) => {
        console.error("Error updating fulfillment request:", error);
        // Handle error (e.g., show an error message to the user)
      },
      onSettled: () => {
        // This will run regardless of success or error
        refetchFulfillmentsList();
      },
    });
  };

  const watchAction = watch("action");

  const rejectionReasons = [
    {
      code: "incorrect_address",
      label: "Incorrect address",
    },
    {
      code: "inventory_out_of_stock",
      label: "Insufficient inventory",
    },
    {
      code: "ineligible_product",
      label: "Ineligible product",
    },
    {
      code: "undeliverable_destination",
      label: "Undeliverable destination",
    },
    {
      code: "other",
      label: "Other",
    },
  ];

  return (
    <>
      <div className="relative mb-4 flex h-32 min-h-32 items-start justify-between rounded-lg border border-gray-200 p-2 text-sm shadow-sm">
        <div className="flex h-full flex-1 flex-col justify-between">
          <div>
            {booking.distributor_order_id && (
              <p className="text-md font-semibold">{booking.distributor_order_id}</p>
            )}
            {!booking.distributor_order_id && (
              <p className="text-md font-semibold text-gray-400">Pending ...</p>
            )}
            {/* Date created */}
            <p className="text-xs text-gray-500">{new Date(booking.created_at).toLocaleString()}</p>
          </div>
          <p className="text-xs text-gray-500">
            {booking.merchant_order?.store_name} / {booking.merchant_order?.store_order_reference}
          </p>
        </div>
        <div className="flex-1">
          Origin
          <p className="text-xs text-gray-500">
            {booking.sender?.city}, {booking.sender?.subdivision_code}
          </p>
        </div>
        <div className="flex-1">
          Destination
          <p className="text-xs text-gray-500">
            {booking.recipient?.city}, {booking.recipient?.subdivision_code}
          </p>
        </div>
        <div className="flex-1">
          Items
          <p className="text-xs text-gray-500">{booking.line_items?.length}</p>
        </div>
        <div className="flex h-full flex-1 justify-end">
          <div className="flex h-full flex-col justify-between">
            <StatusBadge status={booking.distributor_status} />
            <Button onClick={handleOpenModal}>
              Details
              <ChevronRightIcon className="ml-1 h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <div className="flex items-start justify-between">
                      <div>
                        Fulfillment Details{" "}
                        {booking.distributor_order_id && `(${booking.distributor_order_id})`}
                        <br />
                        <p className="text-xs text-gray-500">
                          {new Date(booking.created_at).toLocaleString()}
                        </p>
                      </div>
                      <div className="space-x-2 text-xs text-gray-500">
                        <span>
                          {fullBookingData?.merchant_order?.store_name}
                          {" / "}
                          {fullBookingData?.merchant_order?.store_order_reference}
                        </span>
                        <StatusBadge status={booking.distributor_status} />
                      </div>
                    </div>
                  </Dialog.Title>

                  {isLoadingBooking ? (
                    <p>Loading booking details...</p>
                  ) : (
                    <div className="mt-2">
                      <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2">
                        <AddressCard title="Origin" address={fullBookingData.sender} />
                        <AddressCard title="Destination" address={fullBookingData.recipient} />
                      </div>
                      <h4 className="font-medium">Line Items</h4>
                      <LineItemsTable lineItems={fullBookingData?.line_items} />
                    </div>
                  )}

                  {booking.distributor_status === "submitted" && (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="mt-4 rounded-lg border border-gray-200 p-4"
                    >
                      <Controller
                        name="action"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            className="mt-4"
                            onChange={(value) => {
                              field.onChange(value);
                              setValue("referenceNumber", "");
                              setValue("rejectionReason", "");
                              setValue("rejectionMessage", "");
                            }}
                          >
                            <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                              Choose an action
                            </RadioGroup.Label>
                            <div className="mt-2 flex items-center justify-between space-x-2">
                              {["accept", "reject"].map((value) => (
                                <RadioGroup.Option
                                  key={value}
                                  value={value}
                                  className={({ active, checked }) =>
                                    ` ${
                                      checked
                                        ? "text-black ring ring-offset-indigo-600"
                                        : "bg-white"
                                    } relative flex flex-grow cursor-pointer rounded-md border border-gray-300 px-5 py-4 focus:outline-none`
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center">
                                          <div className="text-sm">
                                            <RadioGroup.Label
                                              as="p"
                                              className={`font-medium ${
                                                checked ? "text-black" : "text-gray-500"
                                              }`}
                                            >
                                              {value.charAt(0).toUpperCase() + value.slice(1)}
                                            </RadioGroup.Label>
                                          </div>
                                        </div>
                                        {checked && (
                                          <div className="shrink-0 text-black">
                                            <CheckIcon className="h-6 w-6" />
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        )}
                      />

                      {watchAction === "accept" && (
                        <>
                          <Controller
                            name="referenceNumber"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <div className="mt-4">
                                <label className="block text-sm font-medium text-gray-700">
                                  Reference Number
                                </label>
                                <input
                                  type="text"
                                  {...field}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                              </div>
                            )}
                          />
                          <Controller
                            name="merchantMessage"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <div className="mt-4">
                                <label className="block text-sm font-medium text-gray-700">
                                  Message (Optional)
                                </label>
                                <textarea
                                  {...field}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  rows={3}
                                />
                                <span className="text-xs leading-3 text-gray-500">
                                  Provide additional information for the merchant
                                </span>
                              </div>
                            )}
                          />
                        </>
                      )}

                      {watchAction === "reject" && (
                        <>
                          <Controller
                            name="rejectionReason"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <div className="mt-4">
                                <label className="block text-sm font-medium text-gray-700">
                                  Rejection Reason
                                </label>
                                <select
                                  {...field}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                >
                                  <option value="">Select a reason</option>
                                  {rejectionReasons.map((reason) => (
                                    <option key={reason.code} value={reason.code}>
                                      {reason.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                          <Controller
                            name="merchantMessage"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <div className="mt-4">
                                <label className="block text-sm font-medium text-gray-700">
                                  Message (Optional)
                                </label>
                                <textarea
                                  {...field}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  rows={3}
                                />
                                <span className="text-xs leading-3 text-gray-500">
                                  Provide additional information about the rejection for the
                                  merchant
                                </span>
                              </div>
                            )}
                          />
                        </>
                      )}

                      <div className="mt-4 flex items-center justify-end space-x-2">
                        <Button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-1 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          disabled={isLoading}
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-1 text-sm font-medium text-indigo-100 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 disabled:opacity-50"
                        >
                          {isLoading ? "Submitting..." : "Submit"}
                        </Button>
                      </div>
                    </form>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const AddressCard = ({ title, address }) => (
  <div className="mb-4 bg-white">
    <h3 className="text-md mb-2 font-semibold">{title}</h3>
    <div className="text-xs">
      <p>{address.name}</p>
      {address.company && <p>{address.company}</p>}
      <p>{address.address1}</p>
      {address.address2 && <p>{address.address2}</p>}
      <p>{`${address.city}, ${address.subdivision_code} ${address.postal_code}`}</p>
      <p>{address.country}</p>
    </div>
  </div>
);

const LineItemsTable = ({ lineItems }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              SKU
            </th>
            <th className="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              Quantity
            </th>
            <th className="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              Unit Price
            </th>
            <th className="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              Total Price
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {lineItems.map((item) => (
            <tr key={item.id}>
              <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-900">
                {item.product.sku}
              </td>
              <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">{item.quantity}</td>
              <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">
                ${parseFloat(item.merchant_order_line_item.store_unit_price).toFixed(2)}
              </td>
              <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">
                $
                {(
                  parseFloat(item.merchant_order_line_item.store_unit_price) * item.quantity
                ).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="bg-gray-50">
          <tr>
            <td
              colSpan="3"
              className="whitespace-nowrap px-6 py-2 text-right text-sm font-medium text-gray-900"
            >
              Total:
            </td>
            <td className="whitespace-nowrap px-6 py-2 text-sm font-medium text-gray-900">
              $
              {lineItems
                .reduce(
                  (total, item) =>
                    total +
                    parseFloat(item.merchant_order_line_item.store_unit_price) * item.quantity,
                  0,
                )
                .toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
