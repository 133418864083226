import React from "react";
import cn from "../utils/classNames";

const Card = ({ children, className }) => {
  return (
    <div className={cn("rounded-lg border border-gray-200 p-2 shadow-sm", className)}>
      {children}
    </div>
  );
};

export default Card;
