import React from "react";

const PartbotIcon = ({ className = "", size = "1em", color = "currentColor", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      width={size}
      height={size}
      fill={color}
      className={`inline-block ${className}`}
      {...props}
    >
      <path d="M82.57 0h334.86C462.986 0 500 37.014 500 82.57v334.86c0 45.556-37.014 82.57-82.57 82.57H82.57C37.013 500 0 462.986 0 417.43V82.57C0 37.013 37.014 0 82.57 0Zm220.962 53.532v226.081c0 3.413 2.847 6.26 6.26 6.26h59.226c3.412 0 6.26-2.847 6.26-6.26v-59.226c0-3.413-2.848-6.26-6.26-6.26h-29.613v-35.873h29.613c23.353 0 41.567 18.79 41.567 42.143v59.226c0 23.353-18.79 41.567-41.567 41.567h-59.226c-23.354 0-42.143-18.79-42.143-41.567V53.532H160.585v85.416c0 2.282-1.706 3.988-3.988 3.988h-27.331c-2.282 0-3.988-1.706-3.988-3.988V53.532H89.405c-19.93 0-35.873 15.942-35.873 35.873V411.16c0 19.93 15.942 35.873 35.873 35.873V220.387c0-23.353 18.79-41.568 41.567-42.143h59.226c23.353 0 41.568 18.79 41.568 42.143v59.226c0 23.353-18.79 41.567-41.568 41.567h-29.613v-35.873h29.613c3.413 0 6.26-2.847 6.26-6.26v-58.66c0-3.413-2.847-6.26-6.26-6.26h-59.226c-3.413 0-6.26 2.847-6.26 6.26v226.081h250v-85.417c0-2.281 1.706-3.988 3.988-3.988h27.332c2.281 0 3.988 1.707 3.988 3.988v85.417c19.93 0 35.873-15.942 35.873-35.873V89.405c.565-19.93-15.377-35.873-34.742-35.873Zm71.756 85.416c0 2.282-1.707 3.988-3.989 3.988h-27.33c-2.283 0-3.989-1.706-3.989-3.988v-27.906c0-2.282 1.706-3.988 3.988-3.988H371.3c2.282 0 3.989 1.706 3.989 3.988Zm-35.873 250c0 2.282-1.707 3.988-3.989 3.988H164.583c-2.281 0-3.988-1.706-3.988-3.988v-27.907c0-2.281 1.707-3.988 3.988-3.988h170.843c2.282 0 3.989 1.707 3.989 3.988Z" />
    </svg>
  );
};

export default PartbotIcon;
