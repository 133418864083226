import React from "react";

export const WeightBadge = ({ weight }) => {
  return (
    <span className="rounded bg-slate-100 p-1 text-xs font-medium text-slate-700/80">
      <span className="font-semibold">{weight}</span>
      <span className="ml-0.5 text-slate-700/50">kg</span>
    </span>
  );
};
