import { useState } from "react";

const useDocumentDownload = (deliveryDocumentFunction) => {
  const [downloadStatus, setDownloadStatus] = useState({});

  const handleDownload = async (documentType, trackingNumber, fileNameSuffix, bookingId) => {
    setDownloadStatus((prev) => ({
      ...prev,
      [fileNameSuffix]: {
        loading: true,
        error: false,
      },
    }));

    try {
      const file = await deliveryDocumentFunction(bookingId, { document_type: documentType });
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${trackingNumber}_${fileNameSuffix}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading document", error);
      setDownloadStatus((prev) => ({
        ...prev,
        [fileNameSuffix]: {
          loading: false,
          error: true,
        },
      }));
    } finally {
      setDownloadStatus((prev) => ({
        ...prev,
        [fileNameSuffix]: {
          loading: false,
          error: prev[fileNameSuffix].error,
        },
      }));
    }
  };

  return {
    downloadStatus,
    handleDownload,
  };
};

export default useDocumentDownload;
