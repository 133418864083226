/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Partbot Distributor API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  DistributorLocation
} from '../../schemas'
import { customInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



/**
 * List all distributor locations
 * @summary List Locations
 */
export const locationsList = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<DistributorLocation[]>(
      {url: `/locations`, method: 'GET', signal
    },
      );
    }
  

export const getLocationsListQueryKey = () => {
    return [`/locations`] as const;
    }

    
export const getLocationsListQueryOptions = <TData = Awaited<ReturnType<typeof locationsList>>, TError = ErrorType<void>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof locationsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationsList>>> = ({ signal }) => locationsList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof locationsList>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationsListQueryResult = NonNullable<Awaited<ReturnType<typeof locationsList>>>
export type LocationsListQueryError = ErrorType<void>

/**
 * @summary List Locations
 */
export const useLocationsList = <TData = Awaited<ReturnType<typeof locationsList>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof locationsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLocationsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get distributor location by ID
 * @summary Get Location
 */
export const locationById = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<DistributorLocation>(
      {url: `/locations/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getLocationByIdQueryKey = (id: string,) => {
    return [`/locations/${id}`] as const;
    }

    
export const getLocationByIdQueryOptions = <TData = Awaited<ReturnType<typeof locationById>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof locationById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationById>>> = ({ signal }) => locationById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof locationById>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationByIdQueryResult = NonNullable<Awaited<ReturnType<typeof locationById>>>
export type LocationByIdQueryError = ErrorType<unknown>

/**
 * @summary Get Location
 */
export const useLocationById = <TData = Awaited<ReturnType<typeof locationById>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof locationById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLocationByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



